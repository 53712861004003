import ApiClient from '@/services/api-client';

export default {
  getSelf() {
    return this.sendRequestWithoutApiPrepended('me', '', 'GET');
  },
  updateProfile(formData) {
    return ApiClient.put('user/profile-information', formData);
  },
  updateBillingAddress(formData) {
    return ApiClient.put('user/billing-address', formData);
  },
  updatePassword(payload) {
    return ApiClient.put('user/password', payload);
  },
  confirmPassword(payload) {
    return this.sendRequestWithoutApiPrepended('user/confirm-password', payload);
  },
  initializeTwoFactor() {
    return this.sendRequestWithoutApiPrepended('user/two-factor-authentication');
  },
  getTwoFactorQRCode() {
    return this.sendRequestWithoutApiPrepended('user/two-factor-qr-code', '', 'GET');
  },
  verifyTwoFactor(payload) {
    return this.sendRequestWithoutApiPrepended('two-factor-challenge', payload, 'POST');
  },
  async login(payload) {
    return this.sendRequestWithoutApiPrepended('login', payload);
  },
  logout() {
    return this.sendRequestWithoutApiPrepended('logout', '', 'POST', { 'X-Skip-Interceptor': true });
  },
  async register(payload) {
    return this.sendRequestWithoutApiPrepended('register', payload);
  },
  sendVerificationEmail() {
    return this.sendRequestWithoutApiPrepended('email/verification-notification');
  },
  sendForgotPasswordEmail(payload) {
    return this.sendRequestWithoutApiPrepended('forgot-password', payload);
  },
  resetPassword(payload) {
    return this.sendRequestWithoutApiPrepended('reset-password', payload);
  },
  setPasswordOnInvite(payload) {
    return ApiClient.post('invite', payload);
  },
  getConfirmedPasswordStatus() {
    return this.sendRequestWithoutApiPrepended('user/confirmed-password-status', '', 'GET');
  },
  async sendRequestWithoutApiPrepended(endpoint, payload = {}, method = 'POST', headers = {}) {
    ApiClient.defaults.baseURL = '/';

    const config = {
      url: endpoint,
      method,
      data: payload,
      headers: {
        ...headers,
      },
    };

    try {
      return await ApiClient.request(config);
    } finally {
      ApiClient.defaults.baseURL = '/api';
    }
  },
  disconnectOauthProvider(provider) {
    return ApiClient.delete(`oauth-providers/${provider}`);
  },
};
