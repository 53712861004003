import authMiddleware from '@/router/middleware/auth-middleware.js';
import useCampaigns from '@/composables/useCampaigns.js';

const { fetchCampaign, campaign } = useCampaigns();

/**
 * Routes that require authentication.
 */

export let authRoutes = [
  {
    name: 'PrivateLayout',
    component: () => import('@/layouts/PrivateLayout.vue'),
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
          permissions: ['dashboard:index'],
        },
      },
      {
        name: 'CampaignReport',
        path: '/analytics/campaign-report',
        component: () => import('@/views/AnalyticsCampaign.vue'),
        meta: {
          permissions: ['analytics:index'],
        },
      },
      {
        name: 'CallReport',
        path: '/analytics/call-report',
        component: () => import('@/views/AnalyticsCall.vue'),
        meta: {
          permissions: ['analytics:index'],
        },
      },
      {
        name: 'WebReport',
        path: '/analytics/web-report',
        component: () => import('@/views/AnalyticsWeb.vue'),
        meta: {
          permissions: ['analytics:index'],
        },
      },
      {
        name: 'DailyReport',
        path: '/analytics/daily-report',
        component: () => import('@/views/AnalyticsDaily.vue'),
        meta: {
          permissions: ['analytics:index'],
        },
      },
      {
        name: 'Clients',
        path: '/clients',
        component: () => import('@/views/Clients.vue'),
        meta: {
          permissions: ['client:index'],
        },
      },
      {
        name: 'Creatives',
        path: '/creatives',
        redirect: '/creatives/my-creatives',
        component: () => import('@/components/BaseWrapper.vue'),
        meta: {
          permissions: ['creative:index'],
        },
        children: [
          {
            name: 'CreativesIndex',
            path: 'my-creatives',
            component: () => import('@/views/CreativesIndex.vue'),
            meta: {
              permissions: ['creative:index'],
            },
          },
          {
            name: 'CreativeTemplatesIndex',
            path: 'template-gallery',
            component: () => import('@/views/CreativesTemplatesIndex.vue'),
            meta: {
              permissions: ['creative:index'],
            },
          },
          {
            name: 'ArchivedCreativesIndex',
            path: 'archived',
            component: () => import('@/views/CreativesArchivedIndex.vue'),
            meta: {
              permissions: ['creative:index'],
            },
          },
        ],
      },
      {
        name: 'ContactListsIndex',
        path: '/contact-lists',
        component: () => import('@/views/ContactListsIndex.vue'),
        meta: {
          permissions: ['contact-list:index'],
        },
      },
      {
        name: 'ContactListsCreate',
        path: '/contact-lists/create',
        component: () => import('@/views/ContactListsCreate.vue'),
        props: { mode: 'create' },
        meta: {
          permissions: ['contact-list:create'],
        },
      },
      {
        name: 'ContactListsAppend',
        path: '/contact-lists/:id/append',
        component: () => import('@/views/ContactListsCreate.vue'),
        props: { mode: 'append' },
        meta: {
          permissions: ['contact-list:update'],
        },
      },
      {
        name: 'ContactListsShow',
        path: '/contact-lists/:id',
        component: () => import('@/views/ContactListsShow.vue'),
        meta: {
          permissions: ['contact-list:index'],
        },
      },
      {
        name: 'ContactListEdit',
        path: '/contact-lists/:id/edit',
        component: () => import('@/views/ContactListEdit.vue'),
        meta: {
          permissions: ['contact-list:update'],
        },
      },
      {
        name: 'Landers',
        path: '/landers',
        redirect: '/landers/my-landers',
        component: () => import('@/components/BaseWrapper.vue'),
        meta: {
          permissions: ['lander:index'],
        },
        children: [
          {
            name: 'LandersIndex',
            path: 'my-landers',
            component: () => import('@/views/LandersIndex.vue'),
            meta: {
              permissions: ['lander:index'],
            },
          },
          {
            name: 'LandersTemplatesIndex',
            path: 'templates',
            component: () => import('@/views/LandersTemplatesIndex.vue'),
            meta: {
              permissions: ['lander:index'],
            },
          },
        ],
      },
      {
        name: 'PhoneNumbersIndex',
        path: '/phone-numbers',
        component: () => import('@/views/PhoneNumbersIndex.vue'),
        meta: {
          permissions: ['phone-number:index'],
        },
      },
      {
        name: 'Integrations',
        path: '/integrations',
        component: () => import('@/views/Integrations.vue'),
        meta: {
          permissions: ['integration:index'],
        },
        children: [
          {
            name: 'IntegrationsIndex',
            path: 'my-integrations',
            component: () => import('@/components/IntegrationsIndex.vue'),
          },
          {
            name: 'IntegrationsConnectMarketVueTo',
            path: 'connect-marketvue',
            component: () => import('@/components/IntegrationsConnectMarketVueTo.vue'),
          },
        ],
      },
      {
        name: 'IntegrationsZapier',
        path: '/integrations/zapier',
        component: () => import('@/views/IntegrationsZapier.vue'),
        meta: {
          permissions: ['integration:view'],
        },
      },
      {
        name: 'ForwardToNumbersIndex',
        path: '/forward-to-numbers',
        component: () => import('@/views/ForwardToNumbersIndex.vue'),
      },
      {
        name: 'ReturnAddressesIndex',
        path: '/return-addresses',
        component: () => import('@/views/ReturnAddressesIndex.vue'),
        meta: {
          permissions: ['return-address:index'],
        },
      },
      {
        name: 'CampaignsIndex',
        path: '/campaigns',
        component: () => import('@/views/CampaignsIndex.vue'),
        meta: {
          permissions: ['campaign:index'],
        },
      },
      {
        name: 'CampaignsShow',
        path: '/campaigns/:id',
        component: () => import('@/views/CampaignsShow.vue'),
        meta: {
          permissions: ['campaign:index'],
        },
      },
      {
        name: 'CampaignsEdit',
        path: '/campaigns/:id/edit',
        component: () => import('@/views/CampaignsEdit.vue'),
        meta: {
          permissions: ['campaign:update'],
        },
      },
      {
        name: 'CampaignsSchedule',
        path: '/campaigns/:id/schedule',
        component: () => import('@/views/CampaignsSchedule.vue'),
        beforeEnter: async (to, from, next) => {
          await fetchCampaign(to.params.id);

          if (!campaign.value.is_schedulable) {
            next({
              name: 'CampaignsIndex',
              params: { pathMatch: to.path.substring(1).split('/') },
            });
          } else {
            next();
          }
        },
        meta: {
          permissions: ['campaign:update'],
        },
      },
      {
        name: 'DomainsIndex',
        path: '/domains',
        component: () => import('@/views/DomainsIndex.vue'),
        meta: {
          permissions: ['domain:create'],
        },
      },
      {
        name: 'Roles',
        path: '/roles',
        component: () => import('@/views/Roles.vue'),
        meta: {
          permissions: ['user:create'],
        },
      },
      {
        name: 'Settings',
        path: '/settings',
        component: () => import('@/views/Settings.vue'),
        redirect: '/settings/profile',
        children: [
          {
            name: 'SettingsProfile',
            path: 'profile',
            component: () => import('@/views/SettingsProfile.vue'),
          },
          {
            name: 'SettingsSecurity',
            path: 'security',
            component: () => import('@/views/SettingsSecurity.vue'),
          },
        ],
      },
      {
        name: 'Users',
        path: '/users',
        component: () => import('@/views/Users.vue'),
        meta: {
          permissions: ['user:index'],
        },
      },
      {
        name: 'Billing',
        path: '/billing',
        meta: {
          permissions: ['subscription:update'],
        },
        children: [
          {
            name: 'PaymentAndBilling',
            path: '/billing/payment-and-invoices',
            component: () => import('@/views/PaymentAndBilling.vue'),
          },
          {
            name: 'ManagePlan',
            path: '/billing/plans',
            component: () => import('@/views/Plan.vue'),
          },
          {
            name: 'Wallet',
            path: '/billing/wallet',
            component: () => import('@/views/Wallet.vue'),
            redirect: '/billing/wallet/charge-history',
            children: [
              {
                name: 'WalletDashboardChargeHistory',
                path: 'charge-history',
                component: () => import('@/components/WalletDashboardChargeHistory.vue'),
              },
              {
                name: 'WalletDashboardStatements',
                path: 'statements',
                component: () => import('@/components/WalletDashboardStatements.vue'),
              },
              {
                name: 'WalletDashboardBalanceNotifications',
                path: 'balance-notifications',
                component: () => import('@/components/WalletDashboardBalanceNotifications.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '',
    name: 'Editor',
    component: () => import('@/layouts/EditorLayout.vue'),
    children: [
      {
        path: '/creatives/:id/edit',
        name: 'CreativeEditor',
        component: () => import('@/views/CreativeEditor.vue'),
        props: {
          template: false,
        },
        meta: {
          permissions: ['creative:update'],
        },
      },
      {
        path: '/creative-templates/:id/edit',
        name: 'CreativeTemplateEditor',
        component: () => import('@/views/CreativeEditor.vue'),
        props: {
          template: true,
        },
        meta: {
          permissions: ['creative:update'],
        },
      },
      {
        path: '/landers/:id/edit',
        name: 'LanderEditor',
        component: () => import('@/views/LanderEditor.vue'),
        props: {
          template: false,
        },
        meta: {
          permissions: ['lander:update'],
        },
      },
      {
        path: '/lander-templates/:id/edit',
        name: 'LanderTemplateEditor',
        component: () => import('@/views/LanderEditor.vue'),
        props: {
          template: true,
        },
        meta: {
          permissions: ['lander:update'],
        },
      },
    ],
  },
  {
    name: 'Agency',
    component: () => import('@/layouts/GuestLayout.vue'),
    children: [
      {
        name: 'ContactOwner',
        path: '/contact-owner',
        component: () => import('@/views/ContactOwner.vue'),
      },
    ],
  },
  {
    name: 'Email',
    component: () => import('@/layouts/GuestLayout.vue'),
    children: [
      {
        name: 'MustVerifyEmail',
        path: '/verify-email',
        component: () => import('@/views/VerifyEmail.vue'),
      },
    ],
  },
];

authRoutes.forEach((layout) => {
  layout.children.forEach((route) => {
    route.meta = {
      ...route.meta,
      middleware: [authMiddleware],
    };
  });
});
