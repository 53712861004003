import AuthApi from '@/api/auth.api.js';
import router from '@/router';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

const HOME_ROUTE = 'CampaignsIndex';

export const useUserStore = defineStore('UserStore', {
  state: () => ({
    user: useStorage('user', {}),
    returnUrl: null,
  }),
  getters: {
    firstName: (state) => state.user?.first_name,
    lastName: (state) => state.user?.last_name,
    fullName: (state) => `${state.user?.first_name} ${state.user?.last_name}` || '',
    email: (state) => state.user?.email,
    planName: (state) => state.user?.plan?.name || 'Free Plan',
    company: (state) => state.user?.company || '',
    companyName: (state) => state.user?.company?.name || '',
    roles: (state) => state.user?.roles || [],
    permissions: (state) => state.user?.permissions || [],
    oauthProviders: (state) => state.user?.oauth_providers || [],
    isAuthenticated: (state) => (state.user ? Object.keys(state.user).length > 0 : false),
    isNotAuthenticated() {
      return !this.isAuthenticated;
    },
    twoFactorEnabled: (state) => state.user?.two_factor_enabled,
    twoFactorConfirmed: (state) => state.user?.two_factor_confirmed,
    isVerified: (state) => state.user?.email_verified_at,
    isNotVerified() {
      return !this.isVerified;
    },
    isSuperAdmin: (state) => state.user?.roles?.includes('Super Admin'),
    isSupport: (state) => state.user?.roles?.includes('Support'),
    isNotSuperAdmin() {
      return !this.isSuperAdmin;
    },
    hasPaymentMethod: (state) => state.user?.company?.pm_last_four !== null,
    hasAddress: (state) => state.user?.company?.address !== null,
    subscriptions: (state) => state.user?.subscriptions || [],
    isOnActivePlan: (state) => state.user?.subscriptions?.length > 0 && !state.user.subscriptions[0].ends_at,
    isSubscribed: (state) => state.user?.is_subscribed,
    isNotSubscribed() {
      return !this.isSubscribed;
    },
    isAgency() {
      return this.$state.user?.is_agency;
    },
    isNotAgency() {
      return !this.isAgency;
    },
    selectedClient: (state) => state.user.selected_client,
    isOwner: (state) => state.roles[0] === 'Owner',
    isNotOwner() {
      return !this.isOwner;
    },
  },
  actions: {
    /**
     * Check if the user has the given permission(s).
     * If the user is Super Admin, this will always return true.
     *
     * @param permission
     * @returns {boolean}
     */
    userCan(permission) {
      return this.isSuperAdmin || this.user?.permissions?.some((p) => permission.includes(p));
    },

    /**
     * Check if the user has permission to the given oauth provider.
     *
     * @param provider
     * @returns {boolean}
     */
    userHasOauthProvider(providerSlug) {
      return this.user?.oauth_providers?.some((o) => providerSlug.toLowerCase().includes(o.slug));
    },

    /**
     * Fetch the authenticated user's details.
     *
     * @returns {Promise<void>}
     */
    async fetchSelf() {
      const response = await AuthApi.getSelf();

      if (response?.status === 200) {
        this.user = response.data?.data;
      } else {
        this.user = {};
      }
    },

    /**
     * Log the user in and push them to their home route.
     * If the user has two-factor authentication enabled,
     * they will be redirected to the two-factor challenge page.
     *
     * @param payload
     * @returns {Promise<*>}
     */
    async login(payload) {
      const response = await AuthApi.login(payload);

      if (response.data?.two_factor) {
        await router.push({ name: 'TwoFactorChallenge' });
      } else {
        await this.fetchSelf();

        await router.push(this.returnUrl || { name: HOME_ROUTE });
      }

      return response;
    },

    /**
     * Log the user out and redirect them to the login page.
     *
     * @returns {Promise<*>}
     */
    async logout() {
      try {
        await AuthApi.logout();
      } catch (error) {
        //
      }
      this.user = {};
      await router.push({ name: 'Login' });
    },

    /**
     * Disconnect an OAuth provider from the authenticated user's account.
     * This will remove the provider from the user's account and revoke the access token.
     * The user will need to re-authenticate with the provider to reconnect it.
     * This will also fetch the user's updated details.
     *
     * @returns {Promise<*>}
     */
    async disconnectOauthProvider(providerSlug) {
      const response = await AuthApi.disconnectOauthProvider(providerSlug);

      await this.fetchSelf();

      return response;
    },

    /**
     * Update the authenticated user's profile information.
     *
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
    async updateProfile(formData) {
      const response = await AuthApi.updateProfile(formData);
      await this.fetchSelf();

      return response;
    },

    /**
     * Update the authenticated user's billing address.
     *
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
    async updateBillingAddress(formData) {
      const response = await AuthApi.updateBillingAddress(formData);
      await this.fetchSelf();

      return response;
    },

    /**
     * Update the authenticated user's password.
     *
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
    async updatePassword(formData) {
      const response = await AuthApi.updatePassword(formData);
      await this.fetchSelf();

      return response;
    },

    /**
     * Check if the user has confirmed their password recently.
     * Used in conjunction with confirmPassword() when you require the user to
     * confirm their password before the action is performed.
     *
     * @returns {Promise<boolean>}
     */
    async checkConfirmedPasswordStatus() {
      const response = await AuthApi.getConfirmedPasswordStatus();

      return response.data?.confirmed === true;
    },

    /**
     * Confirm the user's password.
     * Used in conjunction with checkConfirmedPasswordStatus() when you require the user to
     * confirm their password before the action is performed.
     *
     * @param formData
     * @returns {Promise<AxiosResponse<any>|undefined>}
     */
    async confirmPassword(formData) {
      return await AuthApi.confirmPassword(formData);
    },

    /**
     * Fetch the generated two-factor authentication QR code.
     *
     * @returns {Promise<AxiosResponse<any>|undefined>}
     */
    async fetchTwoFactorQrCode() {
      return await AuthApi.getTwoFactorQRCode();
    },

    /**
     * Verify the user's two-factor authentication code.
     *
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
    async verifyTwoFactor(formData) {
      const response = await AuthApi.verifyTwoFactor(formData);

      if (response.status === 204) {
        await this.fetchSelf();
        await router.push({ name: HOME_ROUTE });
      }

      return response;
    },
  },
});
